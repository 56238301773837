/** @jsx jsx */
import { jsx } from "theme-ui"

const NotFoundPage = () => (
  <div
    sx={{
      width: ["90%", "90%", "90%", "80%"],
      maxWidth: "calc(70ch + 350px + 32px)",
      margin: "0 auto",
    }}
  >
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </div>
)

export default NotFoundPage
